import React from 'react';
import './Contactsection2.css';
const Contactsection2 = () => {
    return (
        <div>
            <section class="ok">
                <div class="contact-div">
                    <div class="contact-div-1">
                        <div class="contact-icons">
                            <i class="fa-regular fa-envelope"></i>
                            <h2>Email</h2>
                            <p>info@ideal-perfume.com</p>
                            <div class="a1"><a href="/"><i class="fa-regular fa-envelope"></i>Send email</a></div>
                        </div>
                        <div class="contact-icons">
                            <i class="fa-solid fa-phone"></i>
                            <h2>Phone</h2>
                            <p>+971 50 659 5864</p>
                            <div class="a1"><a href="/"><i class="fa-solid fa-mobile"></i>Call us</a></div>
                        </div>
                        <div class="contact-icons">
                            <i class="fa-regular fa-map"></i>
                            <h2>Find us</h2>
                            <p>D.S.O, Dubai, United Arab Emirates</p>
                            <div class="a1"><a href="/"><i class="fa-solid fa-mobile"></i>Direction</a></div>
                        </div>
                    </div>
                    <div class="contact-div-2">
                        <form id="form" action="/">
                            <input type="text" placeholder="Name"/>
                            <input type="email" placeholder="E-Mail"/>
                            <input type="text" placeholder="Website"/>
                            <textarea name="comment" placeholder="Message"></textarea>
                            <div class="btn-div"><a href="/" class="form-btn">SUBMIT</a></div>
                        </form>
                    </div>
                </div>
            </section>
        </div>            
    );
}

export default Contactsection2;

import React from 'react';
import ProductSection1 from '../productsection1';
import Productsection2 from '../productsection2';
import ProductYgeni from '../ProductYgeni';
import ProductYgeni1 from '../ProductYgeni1';
import Ingredients from '../Ingredients';
const Product = () => {
    return (
        <div>
            <ProductSection1/>
            <Productsection2/>
            <ProductYgeni/>
            <ProductYgeni1/>
            <Ingredients/>
        </div>
    );
}

export default Product;

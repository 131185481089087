import React from "react";
import "./Shop.css";
const Shopproducts = ({ user, removeRow, method, setcur }) => {
 
  return (
    <div className="shopproducts">
      <img src={"https://parfumeapi.nantech.az/img/"+user.perfumePhotoUrl} />
      <h2>{user.perfumeName}</h2>
      <p>
        {user.perfumePrice}.00<span>  $ </span> 
        <i class="fa-solid fa-trash" onClick={() => removeRow(user.id)}></i>
      </p>
      <a
        className="a-div"
        onClick={() => {
          method(true);
          setcur(user);
        }}
      >
        Add to cart
      </a>
    </div>
  );
};

export default Shopproducts;

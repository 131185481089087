import React from 'react';
import './eau.css'
const Eau = () => {
    return (
        <div>
            <div className="eau">
                <div className="eau-1"><img src="imagevictoria.jpg"/>
                </div>
                <div className="eau-2">
                    <div className="eau-3">
                        <h2>Ygeni EAU De Parfum</h2>
                        <p>“A woman’s perfume tells more about her than her handwriting. ”</p>
                    </div>
                    <div className="eau-4"><img src="secretbombshell.webp"/></div>
                </div>
            </div>
        </div>
    );
}

export default Eau;



import React from 'react';
import './ProductYgeni1.css';
const ProductYgeni1 = () => {
    return (
        <div>
            <div className="products003">
                <div className="product-inspiration-3" >
                    <img src="ingrevictoria.jpg"/>
                </div>
                <div className="product-inspiration-3" >
                    <h2>Ygeni - <span>Ingredients</span></h2>
                    <hr/>
                    <p>Scents are inspired by mysterious sense and beauty of the universe and beyond Arabic character, culture and history is intrinsic to each and every one.</p>
                    <br/>
                    <p>In this way, every scent has its own compelling story, climbing to the next level, the home fragrance is also the heart of the brand. The clarity of the fragrances allows us to be with a home fragrance to create a truly custom-made perfume brand, from energizing citrus and luscious fruits to spectacular florals, intense spices and aromatic woods. The innovation of ideal perfumes, its expertise is rooted in decades of experience rather than artifice and today’s understated elegance is the brand’s hallmark.</p>   
                </div>
            </div>
        </div>
    );
}

export default ProductYgeni1;

import React from "react";
import { useState } from "react";
import "./nav.css";
import { NavLink } from "react-router-dom";
const Nav = ({ basket, setbasket, artir, azalt }) => {
  function basketDel(id) {
    setbasket((data) =>
      data.filter((d) => {
        return d.perfumeId !== id;
      })
    );
  }
  var totalcartprice = 0;
  return (
    <nav>
      <img src="victorialogo.png" />
      <div className="div-logo">
        <ul type="none">
          <li>
            <NavLink to="/" className="href-1">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/Product" className="href-2 href-3">
              Product
            </NavLink>
          </li>
          <li>
            <NavLink to="/Contact" className="href-2">
              Contact
            </NavLink>
          </li>
        </ul>
        <div className="basketBig">
          <i className="fa-solid fa-basket-shopping"></i>
          <div className="basketsmalldiv">
            {basket == false ? (
              <p className="empt">Your cart is empty</p>
            ) : null}

            {basket.map((b) => {
              totalcartprice += b.perfumePrice * b.count;
              return (
                <div className="basketimg" key={b.perfumeId}>
                  <div className="imgsml"><img src={"https://parfumeapi.nantech.az/img/"+b.perfumePhotoUrl} /></div>
                  <div className="baskettext">
                    <h3>{b.perfumeName}</h3>
                    <p>
                      {b.count} x {b.perfumePrice} <span>$</span>
                    </p>
                    <div className="btn-count">
                      <button type="button" onClick={() => azalt(b.perfumeId)}>
                        -
                      </button>
                      <div className="count"><p>{b.count}</p></div>
                      <button type="button" onClick={() => artir(b.perfumeId)}>
                        +
                      </button>{" "}
                    </div>
                  </div>
                  <span className="p-icn" onClick={() => basketDel(b.perfumeId)}>
                  <i class="fa-solid fa-xmark"></i>
                  </span>
                </div>
              );
            })}
            <div className="totalprc">
              <h1>
                Total:
                <span className="sbtotal">{totalcartprice} $</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

    </nav>
  );
};

export default Nav;

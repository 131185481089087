import React from 'react';
import './productsection1.css';

const ProductSection1 = () => {
    return (
        <div>
            <section id="section01" className="demo">
                <div id="section01" className="demo-section">
                    {/* <div className="demo-ust"></div> */}
                        <img src="productsvictoria.jpg"/>
                        <h1>Victoria`s Secret</h1>
                        <p>Eau de Parfum</p>
                        <a href="#section02" className='nese'><span></span></a>
                </div>
            </section>
        </div>
    );
}

export default ProductSection1;

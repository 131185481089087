import React from "react";
import "./Ygeni.css";
const Ygeni = () => {
  return (
    <>
      <div
        id="image2"
        className="img"
        data-img="victoriacollec11.jpg"
      >
        <span className="multiline">
          <span className="max">Victoria`s Secret</span>The scent of your
          embrace...
        </span>
      </div>
      <div
        id="image3"
        className="img"
        data-img="bonbshell2.jpg"
      >
        {/* <span className="multiline-2">
          <span className="max-2">Ideal Oud,Eau de Parfum</span>The scent of
          your embrace...
        </span> */}
      </div>
    </>
  );
};

export default Ygeni;

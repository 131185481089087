import React from "react";
import "./header1.css";
const Header1 = () => {
  return (
    <div className="imgdiv">
      <iframe src="videovictoria.mp4" ></iframe>
    </div>
  );
};

export default Header1;

import React from 'react';
import Shop from '../Shop';

const Shopping = ({addBasket}) => {
    return (
        <>
           <Shop addBasket={addBasket}/>
        </>
    );
}

export default Shopping;

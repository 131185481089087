import React from 'react';
import './ProductYgeni.css';
const ProductYgeni = () => {
    return (
        <div>
            <div className="products002">
                <div className="product-inspiration-2" >
                    <h2>Ygeni - <span>Eau De Parfum</span> </h2>
                    <hr/>
                    <p>From its unique concept and limitless surprises, ideal perfume brand has inspired by and immersed in modern Japanese culture.</p>
                    <br/>
                    <p>Ygeni eau de parfum – the use of unexpected ingredients was considered pioneering. To this date, each scent is characterized by a sophisticated simplicity with a surprising element.</p>
                    <br/>
                    <p>This innovative approach continues to pervade every aspect of the brand, as the creators work with rare elements and notes with a blend of exceptional ingredients.</p>
                </div>
                <div className="product-inspiration-2" >
                    <img src="eaudeparfum.jpg"/>
                </div>
            </div>
        </div>
    );
}

export default ProductYgeni;

import React, {  useEffect, useState } from "react";
import Shopproducts from "./Shopproducts";
import axios from 'axios';
const Shop = ({ addBasket }) => {
  const [modal, setmodal] = useState(false);
  const [cur, setcur] = useState(null);
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get('https://parfumeapi.nantech.az/api/home').then(res => {
      setData(res.data);
    })
  },[]);
  
  const ADD = () => {
    setData(
      data.concat({
        id: 4,
        photo:
          "https://ideal-perfume.com/wp-content/uploads/2019/08/Home-Spray-Camera.24-300x300.jpg",
        name: "Sprey",
        price: 80.0,
        icon: "x",
        product_qty: 0,
      })
    );
  };
  const removeRow = (id) => {
    setData((data) =>
      data.filter((d) => {
        return d.id !== id;
      })
    );
  };

  return (
    <>
      <div class="allproducts">
        <h1>Products</h1>
      </div>
      <div className="shop-div">
        <h1>Shop</h1>
        <p className="shop-p">
          Showing all 3 results{" "}
          <span>
            {" "}
            <i className="fa-solid fa-plus" onClick={ADD}></i>{" "}
          </span>
        </p>
        <div className="shopping">
          {data.map((d) => {
            return (
              <Shopproducts
                user={d}
                key={d.id}
                method={setmodal}
                setcur={setcur}
                removeRow={removeRow}
              />
            );
          })}
        </div>
      </div>
      {modal == true ? (
        <div className="shopbigdiv">
          <div className="shopsmalldiv">
            <div className="smalldiv001">
              <img src={"https://parfumeapi.nantech.az/img/"+cur.perfumePhotoUrl} />
            </div>
            <div className="smalldiv">
              <i
                className="fa-solid fa-xmark"
                onClick={() => setmodal(false)}
              ></i>
              <h1>{cur.perfumeName}</h1>
              <p>
                {cur.perfumePrice} <span>$</span>
              </p>
              <p className="text">
                Scents are inspired by mysterious sense and beauty of the
                universe and beyond Arabic character, culture and history is
                intrinsic to each and every one.
              </p>
              <button className="btnbigdiv" onClick={() => addBasket(cur)}>
                Add to cart
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Shop;

import React from "react";
import "./footer2.css";
const Footer2 = () => {
  return (
    <div className="footer-2">
      <p>SOCIAL MEDIA</p>
      <hr />
      <div className="icons">
        <a href="/">
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        <a href="/">
          {" "}
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
    </div>
  );
};

export default Footer2;

import React from 'react';
import './Ingredients.css';
const Ingredients = () => {
    return (
        <div>
            <div class="ingredients">
                <div class="ingredient-1">
                        <h3>Top Notes</h3>
                        <hr/>
                    <div class="ingredient-img">
                        <div>
                            <img src="peach.jpg"/>
                            <p>Peach</p>
                        </div>
                        <div>
                            <img src="cherryblossom.jpg"/>
                            <p>Cherry Blossom</p>
                         </div>
                    </div>
                </div>
                <div class="ingredient-1">
                        <h3>Middle Notes</h3>
                        <hr/>
                    <div class="ingredient-img">
                        <div> 
                            <img src="lilac.jpg"/>
                            <p>Lilac</p>
                        </div>
                        <div>
                            <img src="jasmin.jpg"/>
                            <p>Jasmin</p>
                        </div>
                    </div>

                </div>
                <div class="ingredient-1">
                    <h3>Base Notes</h3>
                    <hr/>
                <div class="ingredient-img">
                    <div>
                        <img src="tamarid.jpg"/>
                        <p>Tamarid</p>
                    </div>
                    <div>
                        <img src="apple.jpg"/>
                        <p>Red Apple</p>
                    </div>
                </div>

                </div>
            </div>
        </div>
    );
}

export default Ingredients;

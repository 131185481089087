import React from 'react';
import Contactsection1 from '../Contactsection1';
import Contactsection2 from '../Contactsection2';  
const Contact = () => {
    return (
        <div>
            <Contactsection1/>
            <Contactsection2/>
        </div>
    );
}

export default Contact;

import React from 'react';
import './discover.css';
import { NavLink } from "react-router-dom";
const Discover = () => {
    return (
        <div>
            <div className="discover">
                <div className="discovertext">
                    <h2>An unexpected, astonishing and addictive</h2>
                    <p>The notes in that precious liquid will remind you that you love yourself and will tell other people that they ought to love you because you know
                       that you’re worth it. The love affair created by a good perfume between you and other people, you and nature, you and yourself!</p>
                </div>
                <div className="discoverphotos">
                    <div className="photo"><img src="discoverbombshell.jpg"/>
                        <div className="divbutton"><NavLink to="/Shopping" className="button">DISCOVER</NavLink></div>
                    </div>
                     <div className="photo"><img src="teasevictoria.jpg"/>
                        <div className="divbutton"><NavLink to="/Shopping" className="button">DISCOVER</NavLink></div>
                     </div>
                    <div className="photo"><img src="angelgoldvictoria.jpg"/>
                        <div className="divbutton"><NavLink to="/Shopping" className="button">DISCOVER</NavLink></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Discover;



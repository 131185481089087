import React from 'react';
import Ygeni from '../Ygeni';
import Header1 from '../header1';
import Fragran from '../fragran';
import  Eau from '../eau';
import Discover from '../discover'; 
const Home = () => {
    return (
        <>
            <Ygeni/>
            <Header1/>
            <Fragran/>
            <Eau/>
            <Discover/>
        </>
    );
}

export default Home;

import Home from "./Views/Home";
import Layout from "./Views/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Product from "./Views/Product";
import Contact from "./Views/Contact";
import Shopping from "./Views/Shopping";
import "./App.css";
import { useState } from "react";
function App() {
  const [basket, setbasket] = useState([]);
  function addBasket(b) {
    const sert = basket.find((x) => {
      return x.perfumeId === b.perfumeId;
    });
    if (sert === undefined) {
      b.count = 1;
      setbasket(basket.concat(b));
    } else {
      const newState = basket.map((row) => {
        if (row.perfumeId === b.perfumeId) {
          return {...row, count: row.count + 1 };
        }
        return row;
      });
      setbasket(newState);
    }
  }
  const artir = (id) => {
    const newState = basket.map((row) => {
      console.log(id)
      if (row.perfumeId === id) {
        return { ...row, count: row.count + 1 };
      }
      return row;
    });
    setbasket(newState);
  };
  const azalt = (id) => {
    const newState = basket.map((row) => {
      if (row.perfumeId === id && row.count > 1) {
        return { ...row, count: row.count - 1 };
      }
      return row;
    });
    setbasket(newState);
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              basket={basket}
              setbasket={setbasket}
              artir={artir}
              azalt={azalt}
            />
          }
        >
          <Route index element={<Home />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/Contact" element={<Contact />} />
          <Route
            path="/Shopping"
            element={<Shopping addBasket={addBasket} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import "./fragran.css";
const Fragran = () => {
  return (
    <div className="fragrance">
      <div className="div1-frag">
        <h2>The legendary fragrance</h2>
        <p>
          The notes in that precious liquid will remind you that you love
          yourself and will tell other people that they ought to love you
          because you know that you’re worth it. The love affair created by a
          good perfume between you and other people, you and nature!
        </p>
      </div>
      <div className="div2-frag">
        <img src="bombshell22.jpg"/>
      </div>
    </div>
  );
};

export default Fragran;

import React from 'react';
import './productsection2.css';
const Productsection2 = () => {
    return (
        <div>
            <section id="section02" className="demo">
                <div className="products001">
                    <div className="product-inspiration"><img src="victoriasecretbrand.jpg"/></div>
                    <div className="product-inspiration">
                        <h2>The <span>Inspiration</span></h2>
                        <hr/>
                        <p>Timeless and elegant, but always infused with a charming dash of wit and whimsy. It is exactly these distinctly that have underpinned the heritage of ideal<br/> perfume since its inception.</p>
                        <p>From its unique concept and limitless surprises, ideal perfume brand has <br/> inspired by and immersed in modern Japanese and Arabic culture.</p>  
                        <p>Ygeni eau de parfum – the use of unexpected ingredients was considered pioneering. To this date, each scent is characterized by a sophisticated simplicity with a surprising element.</p> 
                        <p>This innovative approach continues to pervade every aspect of the brand, as the creators work with rare elements and notes with a blend of exceptional ingredients.</p> 
                        <p>Scents are inspired by mysterious sense and beauty of the universe and beyond Arabic character, culture and history are intrinsic to each and every one.</p>
                        <p>In this way, every scent has its own compelling story, climbing to the next level, the home fragrance is also the heart of the brand. The clarity of the fragrances allows us to be with a home fragrance to create a truly custom-made perfume brand, from energizing citrus and luscious fruits to specular florals, intense <br/> spices and aromatic woods.</p>
                        <p>The innovation of ideal perfumes, its expertise is rooted in decades of <br/> experience rather than artifice and today’s understated elegance is the brand’s hallmark.</p>
                        <p> Victoria`s secret is a brand that whispers rather than shouts.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Productsection2;

import React, { useState } from "react";
import Nav from "../nav";
import Footer1 from "../footer1";
import Footer2 from "../footer2";
import Footer3 from "../footer3";
import { Outlet } from "react-router-dom";
const Layout = ({ basket,setbasket, artir, azalt}) => {
  return (
    <>
      <Nav basket={basket} setbasket={setbasket} artir={artir} azalt={azalt}/>
      <Outlet />
      <Footer1 />
      <Footer2 />
      <Footer3 />
    </>
  );
};

export default Layout;

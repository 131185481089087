import React from 'react';
import './Contactsection1.css';
const Contactsection1 = () => {
    return (
        <div className='contact11divs'>
            <section>
                <img src="plats111-1.jpg"></img>
                <p class="con-div">Contact</p>
                <div class="scroll-div"></div>
                <a href="#" class="scroll-down" address="true"></a>
            </section>
        </div>
    );
}

export default Contactsection1;

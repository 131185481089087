import React from 'react';
import './footer3.css'
const Footer3 = () => {
    return (
        <div>
             <div className="footer-3">
        <p>© 2023 Ideal Perfume. All rights reserved.</p>
        <div className="icons-2"><a href="/"><i className="fa-brands fa-facebook-f"></i></a><a href="/"> <i className="fa-brands fa-instagram"></i></a></div>
        </div>
        </div>
    );
}

export default Footer3;
